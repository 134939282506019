<template>
  <div class="burn-box" v-if="show">
    <div class="burn-title">
      <span>{{ title }}</span>
      <span class="close" @click="$emit('close')"></span>
    </div>
    <div class="burn-content">
      <slot></slot>
    </div>
    <slot name="btn"></slot>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
  },
  data() {
    return {
      choosedList: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.burn {
  &-box {
    position: fixed;
    top: 50%;
    left: 50%;
    background: #333;
    // border: 1px solid red;
    border-radius: px2rem(5);
    transform: translate(-50%, -50%);
    padding: px2rem(10) px2rem(5);
    width: px2rem(600);
    height: px2rem(600);
    display: flex;
    flex-direction: column;
  }
  &-title {
    color: #fff;
    padding: 0 px2rem(5) px2rem(10);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      width: px2rem(26);
      height: px2rem(26);
      position: relative;
      cursor: pointer;
      &::before,
      &::after {
        content: '';
        display: block;
        width: px2rem(22);
        height: px2rem(2);
        border-radius: px2rem(5);
        position: absolute;
        top: 50%;
        left: 50%;
        background: #fff;
        transform-origin: center;
        transform: translate(-50%, -50%);
        transition: transform 0.3s;
      }
      &:hover {
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
  &-token {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: px2rem(30);
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: px2rem(3) px2rem(8);
    text-align: center;
  }
  &-content {
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: px2rem(5);
    }
    &::-webkit-scrollbar-track {
      background: #423f3f;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #626262;
      border-radius: 10px;
    }
    &::after {
      content: '';
    }
  }
}
</style>
