<template>
  <info-box :show="value" @close="$emit('input', false)">
    <div
      class="burn-item"
      :class="{ checked: item.checked }"
      @click="$emit('choose', item.tokenId)"
      v-for="item in nftList"
      :key="item.tokenId"
    >
      <video
        class="burn-img"
        src="https://figuredata.torizero.com/image/figure.mp4"
        loop
        autoplay
        muted
      ></video>
      <!-- <img class="burn-img" :src="item.image" /> -->
      <div class="burn-token"># {{ item.tokenId }}</div>
    </div>
    <div class="burn-btn" slot="btn" @click="$emit('burn')">BURN</div>
  </info-box>
</template>

<script>
import infoBox from './InfoBox.vue'
export default {
  props: {
    value: Boolean,
    nftList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    infoBox,
  },
}
</script>

<style lang="scss" scoped>
.burn {
  &-item {
    float: left;
    width: calc(100% / 3 - px2rem(10));
    position: relative;
    margin: 0 px2rem(5) px2rem(10);
    cursor: pointer;
    box-sizing: border-box;
    border: px2rem(5) solid transparent;
    padding-bottom: px2rem(38);
    background: #000;
    &.checked {
      border-color: #fff;
    }
  }
  &-token {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: px2rem(30);
    line-height: px2rem(30);
    background: #000;
    color: #fff;
    padding: px2rem(3) px2rem(8);
    text-align: center;
  }
  &-img {
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
  }
  &-btn {
    margin-top: px2rem(20);
    width: px2rem(190);
    height: px2rem(50);
    color: #fff;
    font-size: px2rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    @include bg;
    background-image: url('../assets/mint.png');
    margin: px2rem(20) auto px2rem(10);
    cursor: pointer;
  }
}
</style>
